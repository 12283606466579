/* src/styles.css */
.card-header {
    font-size: 3rem;
    text-align: center;
}

.card-title{
    font-size: 1.5rem;
    text-align: center;
}

.card-text {
    text-align: center;
}